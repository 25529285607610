// Generated by Framer (e4277c8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/uc0aKKpYI";

const cycleOrder = ["URp0qXB16", "ylG9ue9iV"];

const serializationHash = "framer-tZ3TI"

const variantClassNames = {URp0qXB16: "framer-v-4gpq5x", ylG9ue9iV: "framer-v-1jlmt0v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {off: "ylG9ue9iV", on: "URp0qXB16"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, uFVBvFpxT: title ?? props.uFVBvFpxT ?? "Write text here something", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "URp0qXB16", Z9RE1WOOO: link ?? props.Z9RE1WOOO} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, uFVBvFpxT, Z9RE1WOOO, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "URp0qXB16", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={Z9RE1WOOO} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-4gpq5x", className, classNames)} framer-hhwyrv`} data-framer-name={"on"} layoutDependency={layoutDependency} layoutId={"URp0qXB16"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ylG9ue9iV: {"data-framer-name": "off"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 className={"framer-styles-preset-orqdza"} data-styles-preset={"uc0aKKpYI"} style={{"--framer-text-alignment": "left"}}>Write text here something</motion.h2></React.Fragment>} className={"framer-1lptcyd"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"jQW_uA8ga"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={uFVBvFpxT} variants={{ylG9ue9iV: {"--extracted-1of0zx5": "var(--token-d5952ce1-93a0-4567-b4ae-b1ce052cbb65, rgb(255, 255, 255))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({ylG9ue9iV: {children: <React.Fragment><motion.h2 className={"framer-styles-preset-orqdza"} data-styles-preset={"uc0aKKpYI"} style={{"--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-1of0zx5, var(--token-d5952ce1-93a0-4567-b4ae-b1ce052cbb65, rgb(255, 255, 255)))"}}>Write text here something</motion.h2></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tZ3TI.framer-hhwyrv, .framer-tZ3TI .framer-hhwyrv { display: block; }", ".framer-tZ3TI.framer-4gpq5x { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 552px; }", ".framer-tZ3TI .framer-1lptcyd { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tZ3TI.framer-4gpq5x { gap: 0px; } .framer-tZ3TI.framer-4gpq5x > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-tZ3TI.framer-4gpq5x > :first-child { margin-top: 0px; } .framer-tZ3TI.framer-4gpq5x > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 552
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"ylG9ue9iV":{"layout":["fixed","auto"]}}}
 * @framerVariables {"uFVBvFpxT":"title","Z9RE1WOOO":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMD7aMKw5g: React.ComponentType<Props> = withCSS(Component, css, "framer-tZ3TI") as typeof Component;
export default FramerMD7aMKw5g;

FramerMD7aMKw5g.displayName = "1";

FramerMD7aMKw5g.defaultProps = {height: 31, width: 552};

addPropertyControls(FramerMD7aMKw5g, {variant: {options: ["URp0qXB16", "ylG9ue9iV"], optionTitles: ["on", "off"], title: "Variant", type: ControlType.Enum}, uFVBvFpxT: {defaultValue: "Write text here something", displayTextArea: false, title: "Title", type: ControlType.String}, Z9RE1WOOO: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerMD7aMKw5g, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})